export interface PhaseOption {
  label: string;
  value: number;
  valuation: number | null;
  equityFactor: number | null;
}

export interface Stage {
  label: string;
  key: number;
  values: PhaseOption[];
}

export const STAGES = [
// generated and maintained in https://docs.google.com/spreadsheets/d/1ZpWEs8STcUcKpSHOHowU0b-_tnq9A3jXTxPXCu3D4tE/edit?gid=473190478#gid=473190478
{
label: 'Angel / Pre-Seed',
key: 1,
values: [
{ label: '<$250K', value: 2, valuation: 4000000, equityFactor: 2.6 },
{ label: '$250-750K', value: 3, valuation: 6000000, equityFactor: 2.8 },
{ label: '>$750K-2M', value: 4, valuation: 8000000, equityFactor: 3 },
{ label: '>$2M', value: 5, valuation: 15000000, equityFactor: 3.2 },
],
},
{
label: 'Seed',
key: 2,
values: [
{ label: '<$2M', value: 4, valuation: 8000000, equityFactor: 2.8 },
{ label: '$2-5M', value: 5, valuation: 15000000, equityFactor: 3 },
{ label: '$5-10M', value: 6, valuation: 30000000, equityFactor: 3.2 },
{ label: '>$10M', value: 7, valuation: 50000000, equityFactor: 3.4 },
],
},
{
label: 'Series A',
key: 3,
values: [
{ label: '<$10M', value: 6, valuation: 25000000, equityFactor: 3 },
{ label: '$10-20M', value: 7, valuation: 60000000, equityFactor: 3.2 },
{ label: '$20-40M', value: 8, valuation: 80000000, equityFactor: 3.4 },
{ label: '>$40M', value: 9, valuation: 120000000, equityFactor: 3.6 },
],
},
{
label: 'Series B',
key: 4,
values: [
{ label: '<$25M', value: 7, valuation: 75000000, equityFactor: 2.8 },
{ label: '$25-40M', value: 8, valuation: 120000000, equityFactor: 3 },
{ label: '$40-60M', value: 9, valuation: 180000000, equityFactor: 3.2 },
{ label: '>$60M', value: 10, valuation: 300000000, equityFactor: 3.4 },
],
},
{
label: 'Series C/D',
key: 5,
values: [
{ label: '<$50M', value: 8, valuation: 200000000, equityFactor: 2.6 },
{ label: '$50-75M', value: 9, valuation: 300000000, equityFactor: 2.8 },
{ label: '$75-100M', value: 10, valuation: 400000000, equityFactor: 3 },
{ label: '$100-150M', value: 11, valuation: null, equityFactor: 3.2 },
{ label: '>$150M', value: 12, valuation: null, equityFactor: 3.4 },
],
},
{
label: 'Late Stage',
key: 6,
values: [
{ label: '<$100M', value: 10, valuation: null, equityFactor: 3 },
{ label: '$100-150M', value: 11, valuation: null, equityFactor: 3.2 },
{ label: '$150-250M', value: 12, valuation: null, equityFactor: 3.4 },
{ label: '$250-500M', value: 13, valuation: null, equityFactor: 3.6 },
{ label: '$500M-1B', value: 14, valuation: null, equityFactor: 3.8 },
{ label: '$1-2B', value: 15, valuation: null, equityFactor: 4 },
{ label: '>$2B', value: 16, valuation: null, equityFactor: 4.2 },
],
},
{
label: 'Public Enterprise',
key: 7,
values: [
{ label: '10% (e.g., Citrix, Schwab, Yelp, Autodesk, Expedia)', value: 7, valuation: null, equityFactor: 3 },
{ label: '25% (e.g,. Glassdoor, GoDaddy, Salesforce)', value: 9, valuation: null, equityFactor: 3 },
{ label: '40% (e.g., Adobe, Okta)', value: 11, valuation: null, equityFactor: 3 },
{ label: '50% (e.g., Asana, Intuit, DocuSign)', value: 14, valuation: null, equityFactor: 3 },
{ label: '60% (e.g., Uber, Square)', value: 16, valuation: null, equityFactor: 3 },
{ label: '75% (e.g., Coinbase, Airbnb)', value: 18, valuation: null, equityFactor: 3 },
{ label: '90%+ (Google, Meta)', value: 20, valuation: null, equityFactor: 3 },
],
},];

export enum CashAllocationLevel {
  Low = -0.5,
  Mid = 0,
  High = 0.5,
}

export const CASH_ALLOCATION_LEVEL_SLIDER_OPTIONS = [
  {
    label: "Low Cash",
    value: CashAllocationLevel.Low,
  },
  {
    label: "Mid-point",
    value: CashAllocationLevel.Mid,
  },
  {
    label: "High Cash",
    value: CashAllocationLevel.High,
  },
];

export const PRIMARY_BUTTON_COLOR = "#8192ff";

const RESEARCH_AND_DEVELOPMENT_GROUP = "Research & Development";
const SALES_MARKETING_CUSTOMER_GROUP = "Sales, Marketing & Customer";
const GENERAL_ADMINISTRATIVE_GROUP = "General & Administrative";
export const FUNCTIONS_TO_GROUPS: { [key: string]: string } = {
  "Data & Analytics": RESEARCH_AND_DEVELOPMENT_GROUP,
  Design: RESEARCH_AND_DEVELOPMENT_GROUP,
  Engineering: RESEARCH_AND_DEVELOPMENT_GROUP,
  Product: RESEARCH_AND_DEVELOPMENT_GROUP,
  "Support Engineering": RESEARCH_AND_DEVELOPMENT_GROUP,

  "Account Management": SALES_MARKETING_CUSTOMER_GROUP,
  "Business Development": SALES_MARKETING_CUSTOMER_GROUP,
  "Customer Success": SALES_MARKETING_CUSTOMER_GROUP,
  "Customer Support": SALES_MARKETING_CUSTOMER_GROUP,
  Growth: SALES_MARKETING_CUSTOMER_GROUP,
  Marketing: SALES_MARKETING_CUSTOMER_GROUP,
  Sales: SALES_MARKETING_CUSTOMER_GROUP,
  "Sales Operations": SALES_MARKETING_CUSTOMER_GROUP,

  "Business Operations": GENERAL_ADMINISTRATIVE_GROUP,
  "Chief of Staff": GENERAL_ADMINISTRATIVE_GROUP,
  Compliance: GENERAL_ADMINISTRATIVE_GROUP,
  Finance: GENERAL_ADMINISTRATIVE_GROUP,
  "HR / People Operations": GENERAL_ADMINISTRATIVE_GROUP,
  Legal: GENERAL_ADMINISTRATIVE_GROUP,
  "Compliance Operations": GENERAL_ADMINISTRATIVE_GROUP,
  Operations: GENERAL_ADMINISTRATIVE_GROUP,
  Workplace: GENERAL_ADMINISTRATIVE_GROUP,
};
export const GROUP_ORDER = [
  RESEARCH_AND_DEVELOPMENT_GROUP,
  SALES_MARKETING_CUSTOMER_GROUP,
  GENERAL_ADMINISTRATIVE_GROUP,
];
