import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Card } from "./Card";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

interface Option {
  title: string;
  description?: string;
  icon?: JSX.Element;
}

interface Props {
  current?: boolean;
  onClick: (value?: boolean) => void;
  likeOption?: Option;
  dislikeOption?: Option;
  reverse?: boolean;
  error?: string;
}
export const BooleanSelection: React.FC<Props> = ({
  current,
  reverse,
  onClick,
  dislikeOption,
  likeOption,
  error,
}) => {
  const values: boolean[] = reverse ? [false, true] : [true, false];
  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="stretch"
        width="100%"
        spacing={{ xs: 2, sm: 4 }}
        flex={1}
      >
        {values.map((value) => {
          const isApprove = value === true;
          const isSelected = current === value;
          const option = isApprove ? likeOption : dislikeOption;
          const defaultIcon = isApprove ? (
            <FaRegThumbsUp />
          ) : (
            <FaRegThumbsDown />
          );
          const icon = option?.icon;
          const iconBackgroundColor = isApprove ? "#DCFCE7" : "#FEE2E2";
          const iconColor = isApprove ? "#22C55E" : "#EF4444";
          return (
            <Card
              key={value ? "approve" : "deny"}
              sx={{
                width: "100%",
                margin: 0,
                borderColor: "#e2e8f0",
                cursor: "pointer",
                backgroundColor: isSelected ? "#f1f5f9" : undefined,
              }}
              onClick={() => onClick(value)}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    p: 2,
                    color: iconColor,
                    backgroundColor: iconBackgroundColor,
                    borderColor: isSelected ? iconColor : iconBackgroundColor,
                  }}
                >
                  {icon ?? defaultIcon}
                </Box>

                <Stack spacing={1} direction="column">
                  <Typography variant="body1">{option?.title}</Typography>
                  {option?.description && (
                    <Typography variant="subtitle1" color="textSecondary">
                      {option.description}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Card>
          );
        })}
      </Stack>
      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}
    </Stack>
  );
};
