import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import "./App.css";
import { useMutation, useQuery } from "react-query";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { sortBy, uniq, uniqBy } from "lodash";
import {
  CashAllocationLevel,
  PhaseOption,
  STAGES,
  Stage,
  CASH_ALLOCATION_LEVEL_SLIDER_OPTIONS,
  PRIMARY_BUTTON_COLOR as PRIMARY_COLOR,
  FUNCTIONS_TO_GROUPS,
  GROUP_ORDER,
} from "./constants";
import styled from "@emotion/styled";
import { StyledButton } from "./components/StyledButton";
import { ReactComponent as CurrencyIcon } from "./sources/icons/currency-dollar.svg";
import { CommentPrompt } from "./CommentPrompt";

export interface CompInput {
  coefficient: number | null;
  roleFunction: FunctionOption | null;
  stage: Stage | null;
  title: Title | null;
  phase: PhaseOption | null;
  valuation: number | null;
  location: (Location & { group: string }) | null;
}
interface Location {
  name: string;
  index: number;
  adjustment: number;
}

interface Title {
  title: string;
  level: number;
  band: string;
  name: string;
  department: string;
  function: string;
}
interface Range {
  min: number;
  max: number;
}

interface CompOption {
  base: Range; // cash old
  incentive: Range; // equity old
  cashAllocationLevel: CashAllocationLevel;
  equityPercentage: Range | null;
}

export interface CompResult {
  totalComp: Range;
  compOptions: CompOption[];
  comp: CompOption;
}

interface FunctionOption {
  name: string;
  group: string;
}

function App() {
  const PUBLIC_ENTERPRISE_KEY = 9;
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState<
    (Location & { group: string }) | null
  >(null);
  const [roleFunction, setRoleFunction] = useState<FunctionOption | null>(null);
  const [stage, setStage] = useState<Stage | null>(null);
  const [title, setTitle] = useState<Title | null>(null);
  const [phase, setPhase] = useState<PhaseOption | null>(null);
  const [phases, setPhases] = useState<PhaseOption[]>([]);
  const [valuation, setValuation] = useState<number | null>(null);
  const [equityFactor, setEquityFactor] = useState<number | null>(null);
  const [userBlocked, setUserBlocked] = useState<boolean>(false);

  const [coefficient, setCoefficient] = useState<number | null>(null);
  const [cashAllocationLevel, setCashAllocationLevel] = useState<number>(
    CashAllocationLevel.Mid
  );
  const [currentResult, setCurrentResult] = useState<CompResult | null>(null);
  const { data, isLoading } = useQuery<{
    locations: Location[];
    titles: Title[];
  }>("dependencies", () => fetch("/data").then((res) => res.json()));

  const mutation = useMutation("signup", () => {
    return fetch("/login", {
      body: JSON.stringify({
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  });

  const signupCalled = mutation.isSuccess;

  const { mutate, isLoading: calcLoading } = useMutation("calculate", () => {
    const token = localStorage.getItem("AUTH_TOKEN");
    const authHeader: { Authorization?: string } = token
      ? { Authorization: `Bearer ${token}` }
      : {};

    return fetch("/calculate", {
      body: JSON.stringify({
        coefficient,
        level: title?.level,
        colAdj: location?.adjustment || 0,
        valuation: valuation,
        equityFactor: equityFactor,
        cashAllocation: cashAllocationLevel,
        isSalesRole: title?.function.toLocaleLowerCase() === "sales",
      }),
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      method: "POST",
    })
      .then((res) => res.json() as Promise<CompResult>)
      .then((res) => {
        setCurrentResult(res);
      })
      .catch((err) => {
        setModalOpen(true);
      });
  });
  const uniqLocations = useMemo(
    () =>
      uniqBy(data?.locations ?? [], (l) => l.name).map((l) => ({
        ...l,
        group: ["San Francisco", "New York", "San Jose, CA"].some((e) =>
          l.name.includes(e)
        )
          ? "Frequently used"
          : "Other",
      })),
    [data]
  );

  const locations = useMemo(
    () => sortBy(uniqLocations, ["group", "name"]),
    [uniqLocations]
  );

  const titles = uniq(data?.titles ?? []);

  const getFunctionOptions = (titles: Title[]) => {
    const functions = uniq(titles.map((t) => t.function));
    const groupedFunctions = functions.map((f) => ({
      name: f,
      group: FUNCTIONS_TO_GROUPS[f],
    }));
    return sortBy(groupedFunctions, [
      (item) => GROUP_ORDER.indexOf(item.group),
      "name",
    ]);
  };
  const functionOptions = getFunctionOptions(titles);

  const filteredTitles = titles.filter(
    (t) => t.function === roleFunction?.name
  );

  const canCalculate = title && coefficient;

  const submitEmail = async () => {
    // await signUp();
    setUserBlocked(true);
    setModalOpen(false);
  };

  useEffect(() => {
    if (!isLoading && uniqLocations) {
      const defaultOption = uniqLocations.find((location) =>
        location.name.includes("New York")
      );
      if (defaultOption) {
        setLocation(defaultOption);
      }
    }
  }, [isLoading, uniqLocations]);

  const StyledSlider = styled(Slider)({
    color: "rgba(41, 41, 41, 0.20)", // color for the track
    width: "70%",
    "& .MuiSlider-thumb": {
      color: PRIMARY_COLOR,
    },
    "& .MuiSlider-markLabel": {
      fontFamily: "sans-serif",
      fontWeight: 500,
      fontSize: "10px",
      color: "#A9ADAF",
    },
  });

  const selectedCompOption = currentResult?.comp;

  // currentResult?.compOptions.find(
  //   (o) => o.cashAllocationLevel === cashAllocationLevel
  // );
  const cardStyle = {
    border: "1px solid  rgba(41, 41, 41, 0.16)",
    borderRadius: "24px",
    backgroundColor: "#FFFFFF",
  };
  const cardPaddingAndMarginStyle = { p: "32px", margin: "0px" };
  const cardTitleStyle = {
    fontFamily: "sans-serif",
    fontSize: "20px",
    color: "#747778",
    fontWeight: 600,
  };
  const compensationLabelStyle = {
    fontFamily: "sans-serif",
    fontSize: "14px",
    color: "#A9ADAF",
    fontWeight: 600,
  };
  const compensationValueStyle = {
    fontFamily: "sans-serif",
    fontSize: "20px",
    color: "#4E5253",
    fontWeight: 600,
  };
  const titleStyle = {
    fontFamily: "sans-serif",
    fontSize: "32px",
    color: "#292929",
    fontWeight: 600,
  };
  const subTitleStyle = {
    fontFamily: "sans-serif",
    fontSize: "20px",
    color: "#747778",
    fontWeight: 500,
  };
  const dialogContent = {
    fontFamily: "sans-serif",
    fontSize: "16px",
    fontWeight: 400,
  };
  const dropdownLabelStyle = {
    fontFamily: "sans-serif",
    fontSize: "14px",
    color: "#747778",
    fontWeight: 500,
    whiteSpace: "nowrap",
  };
  const renderAutocompleteInput = (params: any) => (
    <TextField
      {...params}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
      }}
    />
  );
  const companyDetailsCard = (
    <Grid container item xs={12}>
      <Grid
        container
        item
        xs={12}
        sx={{ ...cardStyle, ...cardPaddingAndMarginStyle }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography sx={cardTitleStyle}>About the Company</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={dropdownLabelStyle}>Stage</Typography>
          <Autocomplete
            options={STAGES}
            value={stage}
            onChange={(e, val) => {
              setStage(val);
              setPhases(val?.values || []);
              setPhase(null);
            }}
            renderInput={renderAutocompleteInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={dropdownLabelStyle}>
            {stage?.key === PUBLIC_ENTERPRISE_KEY
              ? "Percentile to compensate at"
              : "Amount Raised to Date"}
          </Typography>
          <Autocomplete
            options={phases}
            value={phase}
            disabled={!stage}
            onChange={(e, val) => {
              setPhase(val);
              setCoefficient(val?.value || null);
              setValuation(val?.valuation || null);
              setEquityFactor(val?.equityFactor || null);
            }}
            renderInput={renderAutocompleteInput}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const employeeDetailsCard = (
    <Grid container item xs={12}>
      <Grid
        container
        item
        xs={12}
        sx={{ ...cardStyle, ...cardPaddingAndMarginStyle }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography sx={cardTitleStyle}>About the Employee</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={dropdownLabelStyle}>Function</Typography>
          <Autocomplete
            options={functionOptions}
            value={roleFunction}
            groupBy={(option) => option.group}
            onChange={(e, val) => {
              setRoleFunction(val);
              setTitle(null);
            }}
            getOptionLabel={(opt) => opt.name}
            renderInput={renderAutocompleteInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={dropdownLabelStyle}>Title</Typography>
          <Autocomplete
            options={filteredTitles}
            value={title}
            getOptionDisabled={(option) =>
              Boolean(coefficient && coefficient > 15 && option.level > 11)
            }
            onChange={(e, val) => setTitle(val)}
            getOptionLabel={(opt) => opt.title}
            renderInput={renderAutocompleteInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            placement="bottom-start"
            title="Changing location will apply approximate local Cost of Labor adjustment."
          >
            <Typography sx={dropdownLabelStyle}>Location (Optional)</Typography>
          </Tooltip>
          <Autocomplete
            options={locations}
            value={location}
            groupBy={(option) => option.group}
            onChange={(e, val) => {
              setLocation(val);
            }}
            getOptionLabel={(opt) => opt.name}
            renderInput={renderAutocompleteInput}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const getCompensationDetailsCard = () => {
    return (
      currentResult &&
      selectedCompOption && (
        <Grid container item xs={12} md={5}>
          <Grid container item xs={12} sx={cardStyle}>
            <Grid
              container
              item
              xs={12}
              sx={{ ...cardPaddingAndMarginStyle, paddingBottom: 6 }}
              spacing={2}
            >
              <Grid item textAlign="center" xs={12}>
                <CurrencyIcon />
              </Grid>
              <Grid item textAlign="center" xs={12}>
                <div>
                  <Typography sx={compensationLabelStyle}>
                    {roleFunction?.name.toLocaleLowerCase() === "sales"
                      ? `OTE (Base + Commission)`
                      : `Base Salary`}
                  </Typography>
                  <Typography sx={compensationValueStyle}>
                    {numeral(selectedCompOption.base.min).format("$0,0a")} -{" "}
                    {numeral(selectedCompOption.base.max).format("$0,0a")}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                textAlign="center"
                xs={selectedCompOption.equityPercentage ? 6 : 12}
              >
                <Typography sx={compensationLabelStyle}>
                  {selectedCompOption.equityPercentage == null
                    ? `Incentive Comp (Bonus + Equity, Annualized)`
                    : `Target Equity (Per Year)`}
                </Typography>
                <Typography sx={compensationValueStyle}>
                  {numeral(selectedCompOption.incentive.min).format("$0,0a")} -{" "}
                  {numeral(selectedCompOption.incentive.max).format("$0,0a")}
                </Typography>
              </Grid>
              {selectedCompOption.equityPercentage && (
                <Grid item textAlign="center" xs={6}>
                  <Typography sx={compensationLabelStyle}>
                    Typical Grant (4-Year)
                  </Typography>
                  <Typography sx={compensationValueStyle}>
                    {numeral(
                      selectedCompOption.equityPercentage.min / 100
                    ).format("0.00%")}{" "}
                    -{" "}
                    {numeral(
                      selectedCompOption.equityPercentage.max / 100
                    ).format("0.00%")}
                  </Typography>
                </Grid>
              )}
              <Grid item mt={4} textAlign="center" xs={12}>
                <StyledSlider
                  min={CashAllocationLevel.Low}
                  max={CashAllocationLevel.High}
                  marks={CASH_ALLOCATION_LEVEL_SLIDER_OPTIONS}
                  step={0.5}
                  value={cashAllocationLevel}
                  track={false}
                  onChange={(e, val) => {
                    setCashAllocationLevel(val as number);
                    setTimeout(() => mutate(), 500);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fafafa",
                borderRadius: "0px 0px 24px 24px",
                p: "10px",
              }}
            >
              <Typography sx={compensationLabelStyle}>
                Total Compensation
              </Typography>
              <Typography sx={compensationValueStyle}>
                {numeral(currentResult.totalComp.min).format("$0,0a")} -{" "}
                {numeral(currentResult.totalComp.max).format("$0,0a")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    );
  };
  const calculateButton = (
    <StyledButton
      variant="contained"
      disabled={!canCalculate || calcLoading || userBlocked}
      onClick={() => mutate()}
      sx={{ width: "275px" }}
    >
      {calcLoading && (
        <CircularProgress
          variant="indeterminate"
          size="1.5em"
          sx={{ mr: 2, width: "100000px" }}
        />
      )}
      {!currentResult && "Calculate"}
      {currentResult && "Calculate Again"}
    </StyledButton>
  );

  const headerBox = (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Typography sx={titleStyle}>What's a Fair Offer?</Typography>
      <Typography sx={subTitleStyle} mt={1}>
        For Any Title. At Any Stage. Anywhere.
      </Typography>
    </Box>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      sx={{ height: { md: "100vh" }, backgroundColor: "transparent" }}
    >
      <Dialog open={modalOpen}>
        <DialogTitle>
          <Typography sx={dialogContent} mt={1}>
            Sorry, you've reached your limit! Reach out to{" "}
            <a
              href={`mailto:"comp@abstractops.com"`}
              target="_blank"
              rel="noreferrer"
            >
              comp@abstractops.com
            </a>{" "}
            to get wider access.
          </Typography>
        </DialogTitle>
        <DialogActions>
          <StyledButton onClick={submitEmail} variant="contained">
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
      {signupCalled ? (
        <Box>
          <Typography variant="h2">
            Please check your email to get access!
          </Typography>
          <Typography variant="body1">
            (check your spam folder if you don't see the email!)
          </Typography>
        </Box>
      ) : (
        <Box
          maxWidth={1100}
          p={2}
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
        >
          {/* <StyledButton
            variant="contained"
            href="https://about.fairoffer.ai"
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            Learn More
          </StyledButton> */}
          {headerBox}
          <Grid container mt={2} spacing={4} justifyContent={"center"}>
            <Grid container item xs={12} md={7} spacing={3}>
              {companyDetailsCard}
              {employeeDetailsCard}
            </Grid>
            {getCompensationDetailsCard()}
          </Grid>
          <Box mt={4} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'stretch', sm: 'center' }} justifyContent="center" gap={2} width="100%">
            {calculateButton}
            {currentResult && (
              <CommentPrompt
                currentResult={currentResult}
                input={
                  {
                    coefficient,
                    roleFunction,
                    stage,
                    title,
                    phase,
                    valuation,
                    location,
                  } as CompInput
                }
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default App;
