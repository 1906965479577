import { Paper, PaperProps, styled } from "@mui/material";

export type CardProps = {
  hoverable?: boolean;
  selected?: boolean;
} & PaperProps;
export const Card = styled(Paper)<CardProps>(
  ({ hoverable, selected, theme }) => {
    let borderColor = "transparent";
    if (selected === true) {
      borderColor = "#0ea5e9";
    } else if (selected !== undefined) {
      borderColor = "#E2E8F0";
    }

    return {
      marginBottom: theme.spacing(2),
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.04)",
      borderRadius: theme.spacing(1.5),
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(3),
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: borderColor,
      backgroundColor: selected ? "#f0f9ff" : undefined,
      "&:last-of-type": {
        marginBottom: 0,
      },
      "& .edit-icon": {
        visibility: hoverable ? "hidden" : "visible",
      },
      ...(hoverable && {
        "&:hover": {
          cursor: "pointer",
          boxShadow: "0px 3px 12px 2px rgb(36 40 45 / 5%)",
          borderColor: "#e2e8f0",
          "& .edit-icon": {
            visibility: "visible",
          },
        },
      }),
    };
  }
);
