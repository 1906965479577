import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

export const LoginLinkHandler: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      localStorage.setItem("AUTH_TOKEN", token);
      navigate("/", { replace: true });
    }
  }, [token, navigate]);
  return null;
};
