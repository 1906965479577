import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { BooleanSelection } from "./components/BooleanSelection";
import { CompInput, CompResult } from "./App";
import { StyledButton } from "./components/StyledButton";

export const CommentPrompt: React.FC<{
  currentResult: CompResult;
  input: CompInput;
}> = ({ currentResult, input }) => {
  const [promptOpen, setPromptOpen] = useState(false);
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [likeOrNot, setLikeOrNot] = useState<boolean>(true);
  const { mutate, isLoading } = useMutation("comment", () => {
    const token = localStorage.getItem("AUTH_TOKEN");
    const authHeader: { Authorization?: string } = token
      ? { Authorization: `Bearer ${token}` }
      : {};
    const feedbackSummary =
      "email:" +
      email +
      "LikeOrNot:" +
      likeOrNot +
      " | Comment:" +
      content +
      " | Input:" +
      input +
      " | CompResult:" +
      JSON.stringify(currentResult);
    console.log("feedbackSummary:", feedbackSummary);
    return fetch("/feedback", {
      body: JSON.stringify({
        content: {
          email,
          likeOrNot,
          content,
          input,
          currentResult,
        },
      }),
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      method: "POST",
    })
      .then((res) => res.json())
      .finally(() => {
        setPromptOpen(false);
        setContent("");
        setLikeOrNot(true);
        setEmail("");
      });
  });

  const submitComment = () => {
    mutate();
  };
  return (
    <>
      <Dialog open={promptOpen} fullWidth>
        <DialogTitle>Let us know what you think</DialogTitle>
        <DialogContent>
          <BooleanSelection
            onClick={(value) => {
              setLikeOrNot(value ?? false);
            }}
            current={likeOrNot}
            likeOption={{
              title: "",
              description: "It's helpful.",
            }}
            dislikeOption={{
              title: "",
              description: "Meeeh!",
            }}
          />
          <TextField
            sx={{
              marginTop: 4,
            }}
            fullWidth
            multiline
            placeholder="Feedback..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <TextField
            sx={{
              marginTop: 4,
            }}
            fullWidth
            placeholder="john@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitComment} disabled={!content || isLoading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <StyledButton
        variant="contained"
        onClick={() => setPromptOpen(true)}
        sx={{ width: "275px" }}
      >
        Feedback
      </StyledButton>
    </>
  );
};
